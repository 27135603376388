import React, { useState, useEffect } from 'react';
import GetinTouch from '../GetInTouch';
import GlobalPresence from '../GlobalPresence';
import JoinOurTeam from '../JoinOurTeam';
import Container from '@material-ui/core/Container';
import './ContactUs.scss';
import RequestFormPopup from '../RequestFormPopup';
import { graphql, useStaticQuery } from 'gatsby';

export default function ContactUs({ data, basePath, backgroundImg, host, spaceid, environment, token}) {
  const[available,setAvailable]= useState(false);
  const contactusData = useStaticQuery(graphql`
    query {
      contentfulContactUs {
        pardotFormAvailable
        contentful_id
      }
    }
 `); 
useEffect(() => {
  const fetchContentfulData = async () => {
    const id = contactusData.contentfulContactUs.contentful_id;
    try {
      const response = await fetch(`https://${host}/spaces/${spaceid}/environments/${environment}/entries/${id}?access_token=${token}`);
      const data = await response.json();
      setAvailable(data?.fields?.pardotFormAvailable);
    } catch (error) {
      console.error('Error fetching Contentful data:', error);
    }
  };
  fetchContentfulData();
}, []);
 const [regionalAddress, setRegionalAddress] = useState(
    data?.contentfulContactUs?.chipButton?.chipButtons[0]
  );
  return (
    <>
      <div className="contactus-main">
        <GetinTouch setRegionalAddress={setRegionalAddress} data={data} basePath={basePath} />
        <GlobalPresence data={data} regionalAddress={regionalAddress} />
        <div className="contactus-joinus">
          <Container maxWidth="xl" className='no-gutter'>
          <JoinOurTeam
            data={data?.contentfulContactUs?.joinUsSection}
            matrixImg={data?.contentfulContactUs?.smallDotMatrixImage}
            basePath={basePath}
          />
          </Container>
          <div>
        {available === true ?
    (<RequestFormPopup data={data?.contentfulContactUs?.pardotForm} images={backgroundImg} />) :(<></>)}
          </div>
        </div>
      </div>
    </>
  );
}
