import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import ContactUs from '../components/ContactUs';
import SEO from '../components/seo-component/seo';
import DialogPopupContext from '../utils/dialogpopup-context';

export default function ContactUsPage({location ,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const [open, setOpen] = useState(false);
  const openPopupClick = () => {
    setOpen(true);
  }
  const closePopupClick = () => {
    setOpen(false);
  }
  return (
    <DialogPopupContext.Provider value={{ status: open, openPopup: openPopupClick, closePopup: closePopupClick}}>
    <Layout
      partnerCode={pageContext.partnerCode}
      navigation={pageContext.appData}
      footer={allFooterData}
      styles={pageContext.theme}
      contactFormDetails={pageContext.appData.contactUsFormDetails}
      siteType={pageContext.appData.siteType}
      logout={logoutUser}
      metadata={pageContext.theme.partnerMetaData}
      searchEnabled={pageContext.appData.enableSearch}
      astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
      basePath={pageContext.basePath}
      bucketName={pageContext?.bucketName}
      location={location}
    >
       <SEO
          data={data?.contentfulContactUs?.seoEntry}
          siteurl = {pageContext?.slug}
          bucketName = {pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url+'?fm=webp&q=100'}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          addressSchemaSeo = {data?.allContentfulAddressSchemaSeo?.nodes}
          forPage = 'ContactUs'
          location={location}
        />
      <ContactUs data={data} basePath={pageContext.basePath} backgroundImg={data?.contentfulComponentBackgroundImages}
      host={process.env.GATSBY_CONTENTFUL_HOST}
      spaceid={process.env.GATSBY_CONTENTFUL_SPACE_ID} 
      environment={process.env.GATSBY_CONTENTFUL_ENVIRONMENT}
      token={process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}/>
    </Layout>
    </DialogPopupContext.Provider>
  );
}

export const query = graphql`
  query contactUsQuery($contentful_id: String,$locale: String) {
    contentfulComponentBackgroundImages {
      gridBrightBlueBackgroundImage {
        file {
          url
        }
      }
      donutSliderBackgroundImage {
        file {
          url
        }
      }
      headerDonutImage {
        file {
          url
        }
      }
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
      }
      capsulesBackgroundImage{
        file{
          url
        }
      }
      headerDotMatrixImage{
        file{
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
    }
    allContentfulAddressSchemaSeo(filter: {node_locale: {eq: $locale}}) {
      nodes {
        addressCountry
        addressLocality
        addressRegion
        email
        streetAddress
        postalCode
        telephone
        name
      }
    }
    contentfulContactUs(contentful_id: { eq: $contentful_id },node_locale: { eq: $locale }) {
      entryTitle
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          title
          file {
            url
          }
        }
      }
      middleText
      subMiddleText
      contactUsHeader
      contactUsSubHeader
      formFailureHeader
      formSuccessHeader
      formSuccessSubHeader
      formSuccessBodyText
      formSuccessBodyLink
      formSubmit
      buttonText
      buttonTextLink
      leftArrowImage{
        title
        description
        file {
          fileName
          url
        }
      }
      minusImage {
        file {
          url
        }
        description
      }
      minusHoverImage {
        file {
          url
        }
        description
      }
      plusHoverImage {
        file {
          url
        }
        description
      }
      plusImage {
        description
        file {
          url
        }
      }
      backButtonHoverImage {
        file {
          fileName
          url
        }
      }
      smallDotMatrixImage {
        title
        description
        file {
          contentType
          fileName
          url
        }
      }
      donutBackgroundImage {
        file {
          contentType
          fileName
          url
        }
      }
      bigWorldMapImage {
        title
        description
        file {
          contentType
          fileName
          url
        }
      }
      smallWorldMapImage {
        title
        description
        file {
          contentType
          fileName
          url
        }
      }
      globalAddresses {
        entryTitle
        headerText
        description {
          raw
        }
        contact_us {
          expansionSubText
          expansionText
        }
      }
      joinUsSection {
        entryTitle
        header
        subHeader
        shortDescription {
          raw
        }
        mainImage {
          description
          title
          file {
            fileName
            url
          }
        }
        additionalImage {
          description
          file {
            fileName
            url
          }
        }
        ctaOrPardotForm
        ctaButton {
          displayPrimaryCta
          labelForPrimaryCta
          primaryCtaShouldBeDisplayedAs
          primaryCtaShouldOpenInTheSameTab
          callToAction {
            ... on ContentfulInternalLink {
              id
              slug
              pageName
            }
          }
        }
      }
     pardotFormAvailable
      pardotForm {
        id
        contentful_id
        entryTitle
        header
        subHeader {
          raw
        }
        iFrameLink
        closeIcon {
          description
          file {
            url
          }
        }
      }

      chipButton {
        chipButtons {
          emailSubjectLine
          formName
          buttonText
          formHeading
          formSubHeading
          formInfoText
          formType
          hasNestedButtons
          iFrameLink
          isChipButtonLink
          chipButtonLink {
            link {
              ... on ContentfulInternalLink {
                id
                slug
              }
              ... on ContentfulWebLink {
                id
                url
              }
            }
          }
          deliveryEmail
          regionalAddressHeading {
            headerText
            description {
              raw
            }
          }
          regionalAddresses {
            headerText
            description {
              raw
            }
          }
          nestedButtons {
            chipButtons {
              emailSubjectLine
              formName
              buttonText
              formSubHeading
              formType
              formInfoText
              formHeading
              iFrameLink
              deliveryEmail
              regionalAddressHeading {
                headerText
                description {
                  raw
                }
              }
              regionalAddresses {
                headerText
                description {
                  raw
                }
              }
              jsonFormQuery {
                errorSchema {
                  company {
                    message {
                      pattern
                      required
                    }
                  }
                  firstName {
                    message {
                      pattern
                      required
                    }
                  }
                  jobTitle {
                    message {
                      pattern
                      required
                    }
                  }
                  lastName {
                    message {
                      pattern
                      required
                    }
                  }
                  questionsOrComments {
                    message {
                      pattern
                      required
                    }
                  }
                  workEmail {
                    message {
                      pattern
                      required
                    }
                  }
                }
                uiSchema {
                  questionsOrComments {
                    ui_options {
                      rows
                    }
                    ui_widget
                  }
                  workEmail {
                    ui_widget
                  }
                }
                schema {
                  properties {
                    firstName {
                      title
                      type
                      pattern
                    }
                    lastName {
                      title
                      type
                      pattern
                    }
                    workEmail {
                      title
                      type
                      pattern
                    }
                    company {
                      title
                      type
                    }
                    jobTitle {
                      type
                      title
                      pattern
                    }
                    questionsOrComments {
                      title
                      type
                    }
                  }
                  required
                  type
                }
              }
            }
          }
          jsonFormQuery {
            schema {
              properties {
                firstName {
                  pattern
                  title
                  type
                }

                lastName {
                  pattern
                  title
                  type
                }
                workEmail {
                  pattern
                  title
                  type
                }
                company {
                  title
                  type
                }

                jobTitle {
                  pattern
                  type
                  title
                }

                questionsOrComments {
                  title
                  type
                }
              }
              required
              type
            }
            uiSchema {
              questionsOrComments {
                ui_widget
                ui_options {
                  rows
                }
              }
              workEmail {
                ui_widget
              }
            }
            errorSchema {
              company {
                message {
                  pattern
                  required
                }
              }
              firstName {
                message {
                  pattern
                  required
                }
              }
              jobTitle {
                message {
                  pattern
                  required
                }
              }
              lastName {
                message {
                  pattern
                  required
                }
              }
              questionsOrComments {
                message {
                  pattern
                  required
                }
              }
              workEmail {
                message {
                  pattern
                  required
                }
              }
            }
          }
        }
      }
      dotMatrixBackgroundImage {
        title
        description
        file {
          url
        }
      }
      capsuleBackgroundImage {
        title
        description
        file {
          url
        }
      }
    }
  }
`;
